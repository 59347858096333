import React from 'react';
import roadLineSrc from 'assets/png/road-line.png';
import carSrc from 'assets/png/car.png';
import classnames from '../classnames';

export default function CarFormFallback() {
  return (
    <>
      <h4 className={classnames.title}>Calculation of emitted CO2 in grams when traveling by car</h4>

      <div className={classnames.indents[0]} />

      <div
        className={'relative ml-[-52px] h-[68px] w-[calc(100%+104px)]'}
        style={{
          background: `url(${roadLineSrc})`,
          backgroundRepeat: 'repeat-x',
        }}
      >
        <img
          src={carSrc}
          alt="car on road line picture"
          className={'absolute left-[56px] top-[14px] aspect-[84/40] h-10'}
        />
      </div>

      <div className={classnames.indents[1]} />

      <div className={classnames.fieldsGrid}>
        <div className={classnames.field}>
          <div className={classnames.skeleton[0]} />
          <div className={classnames.skeleton[1]} />
        </div>

        <div className={classnames.field}>
          <div className={classnames.skeleton[0]} />
          <div className={classnames.skeleton[1]} />
        </div>

        <div className={classnames.field}>
          <div className={classnames.skeleton[0]} />
          <div className={classnames.skeleton[1]} />
        </div>
      </div>

      <div className={classnames.indents[2]} />

      <button className={classnames.calcButton}>Calculate</button>
    </>
  );
}
