import clsx from 'clsx';

const getFieldClsx = (className: string) =>
  clsx('flex flex-1 gap-6 rounded-[20px] border border-brand-grey-purple px-7 py-6', className);

export default {
  title: clsx(
    'font-sans text-xl leading-6 tracking-wide text-brand-black',
    'desktop:text-[32px] desktop:leading-[42px]',
  ),
  indents: ['h-9', 'h-5 desktop:h-[60px]', 'h-5 desktop:h-[60px]'],
  calcButton: clsx(
    'rounded-full bg-brand-yellow px-[72px] py-3 shadow-s-orange',
    'text-center font-sans text-base font-semibold text-brand-black',
    'transition-colors hover:bg-brand-yellow-intensive',
    'w-full desktop:w-fit mt-2 desktop:mt-0 h-fit largeDesktop:self-center',
  ),
  fieldsGrid: 'grid desktop:grid-cols-2 largeDesktop:grid-cols-3 gap-x-10 gap-y-4',
  field: getFieldClsx('min-w-full flex-col'),
  fieldAsRow: getFieldClsx('min-w-full'),
  fieldLabel: 'font-sans text-sm font-medium tracking-wide text-brand-grey-dark',
  skeleton: ['h-4 w-full animate-pulse bg-brand-grey-purple', 'h-12 w-full animate-pulse bg-brand-grey-purple'],
  actions: clsx('flex flex-col gap-x-12 gap-y-6', 'largeDesktop:flex-row-reverse largeDesktop:justify-end'),
  indicator: {
    mobile: 'flex flex-wrap items-center gap-x-4 gap-y-2 largeDesktop:hidden',
    desktop: 'hidden largeDesktop:flex items-center gap-x-8 gap-y-2 justify-end flex-1 flex-wrap',
  },
};
