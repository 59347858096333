import React from 'react';
import riverLineSrc from 'assets/png/river-line.png';
import boatSrc from 'assets/png/boat.png';
import classnames from '../classnames';

export default function FerryFormFallback() {
  return (
    <>
      <h4 className={classnames.title}>Calculation of emitted CO2 in grams when traveling by ferry</h4>

      <div className={classnames.indents[0]} />

      <div
        className={'relative ml-[-52px] h-[68px] w-[calc(100%+104px)]'}
        style={{
          background: `url(${riverLineSrc})`,
          backgroundRepeat: 'repeat-x',
        }}
      >
        <img
          src={boatSrc}
          alt="boat on river line picture"
          className={'absolute left-[56px] top-[13px] aspect-[101/34] h-[34px]'}
        />
      </div>

      <div className={classnames.indents[1]} />

      <div className={classnames.fieldsGrid}>
        <div className={classnames.field}>
          <div className={classnames.skeleton[0]} />
          <div className={classnames.skeleton[1]} />
        </div>

        <div className={classnames.field}>
          <div className={classnames.skeleton[0]} />
          <div className={classnames.skeleton[1]} />
        </div>
      </div>

      <div className={classnames.indents[2]} />

      <button className={classnames.calcButton}>Calculate</button>
    </>
  );
}
