import React from 'react';
import trailLineSrc from 'assets/png/trail-line.png';
import trainSrc from 'assets/png/train.png';
import classnames from '../classnames';

export default function TrainFormFallback() {
  return (
    <>
      <h4 className={classnames.title}>Calculation of emitted CO2 in grams when traveling by train</h4>

      <div className={classnames.indents[0]} />

      <div
        className={'relative ml-[-52px] h-[68px] w-[calc(100%+104px)]'}
        style={{
          background: `url(${trailLineSrc})`,
          backgroundRepeat: 'repeat-x',
        }}
      >
        <img
          src={trainSrc}
          alt="plane on clouds line picture"
          className={'absolute left-[56px] top-[12px] aspect-[312/44] h-[44px]'}
        />
      </div>

      <div className={classnames.indents[1]} />

      <div className={classnames.fieldsGrid}>
        <div className={classnames.field}>
          <div className={classnames.skeleton[0]} />
          <div className={classnames.skeleton[1]} />
        </div>

        <div className={classnames.field}>
          <div className={classnames.skeleton[0]} />
          <div className={classnames.skeleton[1]} />
        </div>

        <div className={classnames.field}>
          <div className={classnames.skeleton[0]} />
          <div className={classnames.skeleton[1]} />
        </div>
      </div>

      <div className={classnames.indents[2]} />

      <button className={classnames.calcButton}>Calculate</button>
    </>
  );
}
