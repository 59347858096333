import { TransitType } from './types';

export const transitTypeHashMapping: Record<TransitType, string> = {
  [TransitType.Bus]: '#bus',
  [TransitType.Car]: '#car',
  [TransitType.Ferry]: '#ferry',
  [TransitType.Flight]: '#flight',
  [TransitType.Train]: '#train',
};

export function getInitialTransitMode(): TransitType {
  const { hash } = location;

  const token = hash.toLowerCase();

  return (
    (Object.entries(transitTypeHashMapping).find(([_, typeHash]) => token.startsWith(typeHash))?.[0] as TransitType) ||
    TransitType.Car
  );
}
