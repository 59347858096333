export default function ArrowRoundedIcon({ className }: { className?: string }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className={className}>
      <path
        d="M12.707 8.21089L16.707 12.2109C16.8935 12.3989 16.9982 12.653 16.9982 12.9179C16.9982 13.1827 16.8935 13.4368 16.707 13.6249L12.707 17.6249C12.5184 17.807 12.2658 17.9078 12.0036 17.9056C11.7414 17.9033 11.4906 17.7981 11.3052 17.6127C11.1198 17.4273 11.0146 17.1765 11.0124 16.9143C11.0101 16.6521 11.1109 16.3995 11.293 16.2109L13.586 13.9179H8.00003C7.73481 13.9179 7.48045 13.8125 7.29292 13.625C7.10538 13.4375 7.00003 13.1831 7.00003 12.9179C7.00003 12.6527 7.10538 12.3983 7.29292 12.2108C7.48045 12.0232 7.73481 11.9179 8.00003 11.9179H13.586L11.293 9.62489C11.1109 9.43629 11.0101 9.18368 11.0124 8.92149C11.0146 8.65929 11.1198 8.40848 11.3052 8.22307C11.4906 8.03766 11.7414 7.93249 12.0036 7.93021C12.2658 7.92794 12.5184 8.02873 12.707 8.21089Z"
        fill="#DFDEF4"
      />
      <path
        d="M22.002 12.918C22.002 14.8958 21.4155 16.8292 20.3166 18.4737C19.2178 20.1182 17.656 21.3999 15.8288 22.1568C14.0015 22.9136 11.9909 23.1117 10.051 22.7258C8.11124 22.34 6.32941 21.3876 4.93088 19.989C3.53236 18.5905 2.57995 16.8087 2.1941 14.8689C1.80824 12.9291 2.00628 10.9184 2.76315 9.09114C3.52003 7.26387 4.80176 5.70209 6.44625 4.60327C8.09074 3.50446 10.0241 2.91797 12.002 2.91797C14.6532 2.92088 17.1951 3.97538 19.0698 5.85012C20.9445 7.72485 21.999 10.2667 22.002 12.918ZM11.295 8.21097C11.1075 8.3985 11.0022 8.6528 11.0022 8.91797C11.0022 9.18313 11.1075 9.43744 11.295 9.62497L13.588 11.918H8.00195C7.73673 11.918 7.48238 12.0233 7.29484 12.2109C7.10731 12.3984 7.00195 12.6528 7.00195 12.918C7.00195 13.1832 7.10731 13.4375 7.29484 13.6251C7.48238 13.8126 7.73673 13.918 8.00195 13.918H13.588L11.295 16.211C11.1128 16.3996 11.012 16.6522 11.0143 16.9144C11.0166 17.1766 11.1217 17.4274 11.3071 17.6128C11.4925 17.7982 11.7434 17.9034 12.0056 17.9056C12.2677 17.9079 12.5204 17.8071 12.709 17.625L16.709 13.625C16.8954 13.4369 17.0001 13.1828 17.0001 12.918C17.0001 12.6531 16.8954 12.399 16.709 12.211L12.709 8.21097C12.5214 8.0235 12.2671 7.91818 12.002 7.91818C11.7368 7.91818 11.4825 8.0235 11.295 8.21097Z"
        fill="#8585FF"
      />
    </svg>
  );
}
