import { FunctionComponent } from 'react';
import clsx from 'clsx';
import { TransitType } from 'utils/types';
import { IconProps } from 'assets/svg/types';
import BusIcon from 'assets/svg/Bus';
import CarIcon from 'assets/svg/Car';
import FerryIcon from 'assets/svg/Ferry';
import FlightIcon from 'assets/svg/Flight';
import TrainIcon from 'assets/svg/Train';

const iconByModeMapping: Record<TransitType, FunctionComponent<IconProps>> = {
  [TransitType.Bus]: BusIcon,
  [TransitType.Car]: CarIcon,
  [TransitType.Ferry]: FerryIcon,
  [TransitType.Flight]: FlightIcon,
  [TransitType.Train]: TrainIcon,
};

const labelByModeMapping: Record<TransitType, string> = {
  [TransitType.Bus]: 'Bus',
  [TransitType.Car]: 'Car',
  [TransitType.Ferry]: 'Ferry',
  [TransitType.Flight]: 'Flight',
  [TransitType.Train]: 'Train',
};

type Props = {
  mode: TransitType;
  active?: boolean;
};

export default function TransitModeLabel({ mode, active }: Props) {
  const Icon = iconByModeMapping[mode];
  const label = labelByModeMapping[mode];

  return (
    <div className={'flex items-center gap-6'}>
      <Icon active={active} className={'text-brand-purple-primary'} />

      <p className={clsx('text-xl font-medium tracking-wide', active ? 'text-white' : ' text-brand-black')}>{label}</p>
    </div>
  );
}
