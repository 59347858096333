import clsx from 'clsx';
import { Suspense, useState } from 'react';
import { getInitialTransitMode } from 'utils/transit';
import { tabs } from './config';
import TransitModeSelector from '../TransitModeSelector';

export default function CalcForm() {
  const [transitMode, setTransitMode] = useState(getInitialTransitMode);

  return (
    <section
      className={clsx(
        'mx-auto flex w-full rounded-[30px] bg-brand-grey-light ',
        'mt-[-36px] desktop:mt-[-160px]',
        'max-w-[1540px] flex-col px-4 py-9 desktop:px-[min(120px,5vw)] desktop:py-[min(102px,14vh)]',
      )}
    >
      <h2 className="text-2xl font-bold leading-7 tracking-wide text-brand-black desktop:text-[48px] desktop:leading-none">
        Choose the desired type of transport for calculation:
      </h2>

      <div className="h-5 desktop:h-16" />

      <TransitModeSelector selected={transitMode} onSelect={setTransitMode} />

      <div className="h-4 desktop:h-[60px]" />

      {tabs.map(({ mode, Content, ContentFallback }) => (
        <div
          key={mode}
          className={
            transitMode === mode
              ? clsx(
                  'flex w-full max-w-[1460px] flex-col overflow-hidden rounded-[30px] bg-white px-4 py-6 shadow-s-purple',
                  'desktop:z-0 desktop:p-12',
                )
              : 'hidden'
          }
        >
          <Suspense fallback={<ContentFallback />}>
            <Content />
          </Suspense>
        </div>
      ))}
    </section>
  );
}
