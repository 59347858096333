import { IconProps } from './types';

export default function CarIcon({ active, className }: IconProps) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" className={className}>
      <circle cx="20" cy="20" r="20" fill={active ? 'white' : 'currentColor'} />
      <mask
        id="mask0_5508_1441"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <circle cx="20" cy="20" r="20" fill={active ? 'white' : 'currentColor'} />
      </mask>

      <g mask="url(#mask0_5508_1441)">
        <path
          d="M20.5879 24.4814C22.9402 24.4814 24.8472 26.3884 24.8472 28.7408C24.8472 31.0931 22.9402 33.0001 20.5879 33.0001C18.2366 32.9975 16.3311 31.092 16.3285 28.7408C16.3285 26.3884 18.2355 24.4814 20.5879 24.4814Z"
          fill={active ? 'currentColor' : 'white'}
        />

        <path
          d="M-19.3141 19.0129L-14.5667 16.8459C-14.4049 16.7741 -14.268 16.6558 -14.1731 16.5063L-10.0593 9.94862C-9.29312 8.72547 -7.94698 7.98779 -6.50372 8.00015H10.2139C11.5191 7.99618 12.7517 8.6001 13.5484 9.6339L18.8389 16.5648C18.9572 16.7232 19.1248 16.8378 19.3154 16.8905L28.1573 19.31C29.4201 19.6616 30.2905 20.8156 30.2817 22.1264V25.3598C30.2817 26.9695 29.0236 28.3083 27.4137 28.3083H26.6434C26.4164 25.317 24.0391 22.9396 21.0477 22.7126C17.7035 22.4588 14.7867 24.964 14.5329 28.3083H-4.61323C-4.84025 25.317 -7.21758 22.9397 -10.2089 22.7126C-13.5531 22.4588 -16.4699 24.964 -16.7237 28.3083H-18.1318C-19.7417 28.3083 -20.9998 26.9695 -20.9998 25.3598V21.6687C-21.0089 20.5289 -20.3493 19.4897 -19.3141 19.0129Z"
          fill={active ? 'currentColor' : 'white'}
        />
      </g>
    </svg>
  );
}
