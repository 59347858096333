import React from 'react';
import clsx from 'clsx';
import heroWebpSrc from 'assets/webp/hero.webp';
import heroPngSrc from 'assets/png/hero.png';
import heroWebp2xSrc from 'assets/webp/hero-x2.webp';
import heroPng2xSrc from 'assets/png/hero-x2.png';

export default function Welcome() {
  return (
    <div
      className={clsx(
        'grid desktop:grid-cols-[min(50%,710px)_auto]',
        'pt-[10vh]',
        'relative mx-auto max-w-[1440px] px-4 desktop:px-8',
      )}
    >
      <picture className={'desktop:hidden'}>
        <source srcSet={heroWebpSrc} />

        <img
          alt="Calculator and note displayed as 3d image"
          src={heroPngSrc}
          className={'mx-auto aspect-square w-full max-w-[408px]'}
        />
      </picture>

      <div className={'mt-[-64px] flex flex-col justify-center desktop:mt-0'}>
        <p
          className={'w-fit rounded-full bg-brand-violet-light/20 p-3 text-xs font-semibold text-brand-violet-primary'}
        >
          Estimator
        </p>

        <h1 className={'mt-8 text-[48px] font-bold leading-none tracking-wide text-brand-grey-light'}>
          Carbon Footprint Calculator
        </h1>

        <p
          className={
            'mt-4 whitespace-pre-wrap text-base font-light tracking-wide text-brand-grey-light desktop:text-xl desktop:leading-9'
          }
        >
          Did you know a road trip might be leaving more than just memories in its wake? Transportation emissions are
          our reality, but you can make an impact by measuring them accurately and staying informed. This calculator
          helps you track your carbon footprint from driving by evaluating your {"vehicle's"} emissions based on
          mileage, fuel type and other factors.
          <br />
          <br />
          Use this tool to get on the path toward a lower-emission life. Every ounce of carbon saved makes a difference.
        </p>
      </div>

      <picture className={'hidden desktop:block'}>
        <source srcSet={heroWebp2xSrc} />

        <img alt="Calculator and note displayed as 3d image" src={heroPng2xSrc} className={'aspect-square w-full'} />
      </picture>
    </div>
  );
}
