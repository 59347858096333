import clsx from 'clsx';
import React from 'react';
import logoSrc from 'assets/png/logo.png';
import ArrowRoundedIcon from 'assets/svg/ArrowRounded';

export default function Header() {
  return (
    <header className={'absolute left-0 right-0 top-0 flex justify-center px-4 py-6 desktop:px-8'}>
      <div className={'flex w-full max-w-[1760px] items-center justify-between'}>
        <a href={'/'}>
          <img src={logoSrc} alt={'GENiUSEE ESTIMATOR'} className={'w-[177px] desktop:w-[268px]'} />
        </a>

        <a
          href={'https://geniusee.com/'}
          className={clsx(
            'w-fit rounded-full px-4 py-2 font-sans text-[11px] font-semibold leading-[13.2px]',
            'border border-brand-purple-dark-100 bg-brand-purple-dark-200/60  text-brand-grey-purple',
            'desktop:hidden',
          )}
        >
          geniusee.com
        </a>

        <a
          href={'https://geniusee.com/'}
          className={clsx(
            'w-fit rounded-full px-7 py-4 font-sans text-base font-semibold leading-[19.2px]',
            'border border-brand-purple-dark-100 bg-brand-purple-dark-200/60 text-brand-grey-purple',
            'items-center gap-2 shadow-lg transition-shadow duration-500 hover:shadow-brand-purple-check/20',
            'hidden desktop:flex',
          )}
        >
          Back to geniusee.com
          <ArrowRoundedIcon />
        </a>
      </div>
    </header>
  );
}
