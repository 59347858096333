import { IconProps } from './types';

export default function FlightIcon({ active, className }: IconProps) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" className={className}>
      <circle cx="20" cy="20" r="20" fill={active ? 'white' : 'currentColor'} />
      <mask
        id="mask0_5508_3673"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <circle cx="20" cy="20" r="20" fill={active ? 'white' : 'currentColor'} />
      </mask>
      <g mask="url(#mask0_5508_3673)">
        <path
          d="M11.1238 36H9.07613C8.87038 35.9999 8.66793 35.9483 8.48729 35.8498C8.30665 35.7513 8.15359 35.6091 8.04211 35.4361C7.93062 35.2632 7.86427 35.0651 7.84912 34.8599C7.83396 34.6547 7.87049 34.449 7.95536 34.2615L12.9138 23.3192L5.46844 23.1538L2.75306 26.4438C2.23536 27.0946 1.82229 27.3846 0.76844 27.3846H-0.610021C-0.828274 27.3916 -1.045 27.3459 -1.24184 27.2514C-1.43868 27.1569 -1.60983 27.0163 -1.74079 26.8415C-1.92387 26.5946 -2.10387 26.1762 -1.92848 25.5792L-0.403867 20.1177C-0.392329 20.0769 -0.378483 20.0362 -0.363098 19.9962C-0.362331 19.9923 -0.362331 19.9884 -0.363098 19.9846C-0.378984 19.9446 -0.392596 19.9038 -0.403867 19.8623L-1.93002 14.3662C-2.09541 13.7808 -1.91464 13.3715 -1.7331 13.1308C-1.61119 12.969 -1.45304 12.8381 -1.27134 12.7486C-1.08965 12.6591 -0.889482 12.6135 -0.686944 12.6154H0.76844C1.55536 12.6154 2.31921 12.9685 2.76844 13.5385L5.42767 16.7731L12.9138 16.6623L7.9569 5.73923C7.87191 5.55189 7.83524 5.34622 7.85024 5.14105C7.86523 4.93587 7.93142 4.73773 8.04275 4.56473C8.15407 4.39174 8.30699 4.24941 8.48751 4.15075C8.66804 4.0521 8.87041 4.00027 9.07613 4H11.1461C11.4349 4.0058 11.7187 4.07659 11.9764 4.20709C12.2341 4.3376 12.459 4.52448 12.6346 4.75385L22.2538 16.4462L26.6977 16.3292C27.0231 16.3115 27.9246 16.3054 28.1331 16.3054C32.3838 16.3077 34.9223 17.6877 34.9223 20C34.9223 20.7277 34.6315 22.0769 32.6861 22.9354C31.5377 23.4431 30.0054 23.7 28.1315 23.7C27.9254 23.7 27.0261 23.6938 26.6961 23.6762L22.2531 23.5577L12.61 35.25C12.4343 35.4784 12.2095 35.6643 11.9523 35.7941C11.695 35.924 11.4119 35.9943 11.1238 36Z"
          fill={active ? 'currentColor' : 'white'}
        />
      </g>
    </svg>
  );
}
