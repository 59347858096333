import { IconProps } from './types';

export default function TrainIcon({ active, className }: IconProps) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" className={className}>
      <circle cx="20" cy="20" r="20" fill={active ? 'white' : 'currentColor'} />
      <mask
        id="mask0_5508_3688"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <circle cx="20" cy="20" r="20" fill={active ? 'white' : 'currentColor'} />
      </mask>

      <g mask="url(#mask0_5508_3688)">
        <path
          d="M27.9263 22.5969C26.471 13.5593 18.988 7 10.1347 7H-7.15256e-07C-0.552285 7 -1 7.44772 -1 8V10.4C-1 10.9523 -0.552285 11.4 0 11.4H4.31538C4.9022 11.4 5.37846 11.8928 5.37846 12.5V19.1C5.37846 19.7072 4.9022 20.2 4.31538 20.2H1.19209e-07C-0.552285 20.2 -1 20.6477 -1 21.2V28C-1 28.5523 -0.552285 29 0 29H22.6832C25.9692 29 28.4664 25.9453 27.9263 22.5969ZM22.0911 19.6511C21.9008 19.991 21.55 20.2 21.1715 20.2H9.63077C9.04395 20.2 8.56769 19.7072 8.56769 19.1V12.5C8.56769 11.8928 9.04395 11.4 9.63077 11.4H10.1347C15.0716 11.4 19.6545 14.1412 22.0943 18.5533C22.2824 18.8943 22.2814 19.3123 22.0911 19.6511Z"
          fill={active ? 'currentColor' : 'white'}
        />

        <path
          d="M29 32C29 32.5523 28.5523 33 28 33H8.34465e-07C-0.552284 33 -1 32.5523 -1 32C-1 31.4477 -0.552285 31 0 31H28C28.5523 31 29 31.4477 29 32Z"
          fill={active ? 'currentColor' : 'white'}
        />
      </g>
    </svg>
  );
}
