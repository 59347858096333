import React, { useId, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import { TransitType } from 'utils/types';
import TransitModeLabel from 'components/ui/TransitModeLabel';
import { transitTypeHashMapping } from 'utils/transit';

export type TransitModeSelectProps = {
  selected: TransitType;
  onSelect(t: TransitType): void;
};

function SelectWithTooltip({ selected, onSelect, className }: TransitModeSelectProps & { className?: string }) {
  const tooltipId = useId();
  const [openedTooltip, setOpenedTooltip] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [buttonWidth, setButtonWidth] = useState(328);

  return (
    <>
      <button
        ref={buttonRef}
        type="button"
        data-tooltip-id={tooltipId}
        className={clsx(
          'flex items-center justify-between rounded-[60px] p-3 shadow-s-purple transition-colors',
          openedTooltip ? 'bg-brand-purple-primary' : 'bg-white',
          className,
        )}
        onClick={() => setButtonWidth(buttonRef.current?.offsetWidth || 328)}
      >
        <TransitModeLabel mode={selected} active={openedTooltip} />

        <svg
          width="16"
          height="9"
          viewBox="0 0 16 9"
          fill="none"
          className={clsx('mr-2', openedTooltip ? 'rotate-180 text-white' : 'text-brand-black')}
        >
          <path
            d="M0.277967 1.61977C-0.315829 1.02203 0.104721 0 0.944476 0L15.0555 0C15.8953 0 16.3158 1.02203 15.722 1.61977L8.66651 8.72209C8.29841 9.09264 7.70159 9.09264 7.33349 8.72209L0.277967 1.61977Z"
            fill="currentColor"
          />
        </svg>
      </button>

      {ReactDOM.createPortal(
        <Tooltip
          id={tooltipId}
          clickable
          openOnClick
          variant="light"
          opacity={1}
          isOpen={openedTooltip}
          setIsOpen={setOpenedTooltip}
          place="bottom"
          style={{ padding: 16, width: buttonWidth, borderRadius: '30px' }}
          className={clsx('flex flex-col gap-4 border border-brand-grey-light shadow-lg', 'desktop:hidden')}
        >
          {Object.values(TransitType)
            .filter((mode) => mode !== selected)
            .map((mode) => (
              <a
                key={mode}
                href={transitTypeHashMapping[mode]}
                onClick={() => {
                  onSelect(mode);
                  setOpenedTooltip(false);
                }}
                className={'py-2'}
              >
                <TransitModeLabel mode={mode} />
              </a>
            ))}
        </Tooltip>,
        document.body,
      )}
    </>
  );
}

const orderedModes = [TransitType.Car, TransitType.Bus, TransitType.Ferry, TransitType.Flight, TransitType.Train];

export default function TransitModeSelector({ selected, onSelect }: TransitModeSelectProps) {
  return (
    <>
      <SelectWithTooltip selected={selected} onSelect={onSelect} className={'desktop:hidden'} />

      <div className={'hidden flex-wrap gap-x-10 gap-y-6 desktop:flex'}>
        {orderedModes.map((mode) => {
          const active = mode === selected;
          return (
            <a
              key={mode}
              href={transitTypeHashMapping[mode]}
              onClick={() => onSelect(mode)}
              className={clsx(
                'relative flex min-w-[128px] max-w-[228px] flex-1 rounded-full p-3 transition-colors',
                active ? 'bg-brand-purple-primary' : 'bg-white shadow-s-purple hover:bg-brand-grey-purple',
              )}
            >
              <TransitModeLabel mode={mode} active={active} />

              {active && <div className="absolute left-[30%] top-[calc(100%+56px)] z-0 h-20 w-20 rotate-45 bg-white" />}
            </a>
          );
        })}
      </div>
    </>
  );
}
