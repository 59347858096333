import { IconProps } from './types';

export default function BusIcon({ active, className }: IconProps) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" className={className}>
      <mask
        id="mask0_5475_7222"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="40"
        height="40"
      >
        <circle cx="20" cy="20" r="20" fill={active ? 'white' : 'currentColor'} />
      </mask>

      <g mask="url(#mask0_5475_7222)">
        <circle cx="20" cy="20" r="20" fill={active ? 'white' : 'currentColor'} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-24.3636 9.02063C-24.3636 8.29547 -23.7705 7.70245 -23.0455 7.70245H-13.8182V18.2479H-24.3636V9.02063ZM-11.1818 7.70245H-0.636364V18.2479H-11.1818V7.70245ZM2 7.70245H12.5455V18.2479H2V7.70245ZM15.1818 7.70245H23.9478C25.2 7.70245 26.3205 8.62501 26.5182 9.87711L28.0341 19.2365C28.1659 20.2252 28.2978 21.2138 28.2978 22.2024H19.1364L15.1818 18.2479V7.70245ZM-27 7.70245V31.4297C-27 32.8796 -25.8136 34.0661 -24.3636 34.0661H-24.2318C-24.2977 33.6705 -24.3636 33.2092 -24.3636 32.7479C-24.3636 29.1229 -21.3977 26.157 -17.7727 26.157C-14.1477 26.157 -11.1818 29.1229 -11.1818 32.7479C-11.1818 33.2092 -11.2477 33.6705 -11.3136 34.0661H12.6773C12.6114 33.6705 12.5455 33.2092 12.5455 32.7479C12.5455 29.1229 15.5114 26.157 19.1364 26.157C22.7614 26.157 25.7273 29.1229 25.7273 32.7479C25.7273 33.2092 25.6614 33.6705 25.5955 34.0661H28.3636C29.8136 34.0661 31 32.8796 31 31.4297V22.9933C31 21.609 30.8682 20.1591 30.6705 18.7748L29.1545 9.35017C28.6932 6.84554 26.5182 5 23.9478 5H-24.3636C-25.8136 5.06609 -27 6.25211 -27 7.70245Z"
          fill={active ? 'currentColor' : 'white'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.7502 32.7474C23.7502 35.2954 21.6847 37.3611 19.1366 37.3611C16.5885 37.3611 14.5229 35.2954 14.5229 32.7474C14.5229 30.1995 16.5885 28.1338 19.1366 28.1338C21.6847 28.1338 23.7502 30.1995 23.7502 32.7474Z"
          fill={active ? 'currentColor' : 'white'}
        />
      </g>
    </svg>
  );
}
