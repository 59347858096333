import { FunctionComponent, lazy } from 'react';
import { TransitType } from 'utils/types';
import CarContentFallback from './tabs/car/fallback';
import BusContentFallback from './tabs/bus/fallback';
import FerryContentFallback from './tabs/ferry/fallback';
import FlightContentFallback from './tabs/flight/fallback';
import TrainContentFallback from './tabs/train/fallback';

/*
For single-html builds
import BusContent from './tabs/bus';
import CarContent from './tabs/car';
import FerryContent from './tabs/ferry';
import FlightContent from './tabs/flight';
import TrainContent from './tabs/train';
 */

export const tabs: {
  mode: TransitType;
  Content: FunctionComponent;
  ContentFallback: FunctionComponent;
}[] = [
  {
    mode: TransitType.Car,
    Content: lazy(() => import('./tabs/car')),
    //Content: CarContent,
    ContentFallback: CarContentFallback,
  },
  {
    mode: TransitType.Bus,
    Content: lazy(() => import('./tabs/bus')),
    //Content: BusContent,
    ContentFallback: BusContentFallback,
  },
  {
    mode: TransitType.Ferry,
    Content: lazy(() => import('./tabs/ferry')),
    //Content: FerryContent,
    ContentFallback: FerryContentFallback,
  },
  {
    mode: TransitType.Flight,
    Content: lazy(() => import('./tabs/flight')),
    //Content: FlightContent,
    ContentFallback: FlightContentFallback,
  },
  {
    mode: TransitType.Train,
    Content: lazy(() => import('./tabs/train')),
    //Content: TrainContent,
    ContentFallback: TrainContentFallback,
  },
];
