import React from 'react';
import Header from './components/Header';
import Welcome from './components/Welcome';
import CalcForm from './components/CalcForm';

export default function App() {
  return (
    <main className="relative bg-brand-black">
      <div className="pb-24 desktop:min-h-screen">
        <Welcome />

        <Header />
      </div>

      <div className="relative flex w-full flex-col bg-white">
        <CalcForm />

        <footer className="px-4 pb-4 pt-10">
          <p className="text-center font-sans text-sm font-medium tracking-wide text-brand-black">
            @ {new Date().getFullYear()} Geniusee and/or its affiliates. All rights reserved.
          </p>
        </footer>
      </div>
    </main>
  );
}
